import React, { useState, useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { selectModels } from "state/reducers/modelSlice";

import SidebarItemFooter from "./SidebarItemFooter";
import SidebarItemHeader from "./SidebarItemHeader";
import SidebarItemList from "./SidebarItemList";

import "./SidebarItem.css";

// Define sorting type and options
type SortOption = "name" | "material";
type SortDirection = "asc" | "desc";

interface SidebarItemProps {
  scene: BABYLON.Scene;
  itemType: string;
  items: any[];
  selectedItems: any[];
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>;
  pasteSavedModel: any;
  setChildContextMenuEnabled?: (value: boolean) => void;
}

const SidebarItem = ({
  scene,
  itemType,
  items,
  selectedItems,
  setItems,
  setSelectedItems,
  pasteSavedModel,
  setChildContextMenuEnabled,
}: SidebarItemProps) => {
  const models = useAppSelector(selectModels);
  const modelsToDraw = Object.values(models);
  const arrayModel = modelsToDraw.flat();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sortBy, setSortBy] = useState<SortOption>("name");
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  // Add sort handler
  const handleSort = (option: SortOption) => {
    if (sortBy === option) {
      // Toggle direction if same option
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      // Set option with default ascending direction
      setSortBy(option);
      setSortDirection("asc");
    }
  };

  // Create sorted and filtered models using useMemo for performance
  const filteredAndSortedModels = useMemo(() => {
    const filtered = arrayModel.filter((model) => model.category === itemType);

    return [...filtered].sort((a, b) => {
      // For non-Object types, always sort by name
      const sortField = itemType === "Objects" ? sortBy : "name";
      const sortDir = itemType === "Objects" ? sortDirection : "asc";

      // Handle possible undefined values
      const aName = (a[sortField] || "").toString();
      const bName = (b[sortField] || "").toString();

      // For "Ports", "Lumped Elements" and "Distances" types, implement natural sort
      if (
        itemType === "Ports" ||
        itemType === "Lumped Elements" ||
        itemType === "Distances"
      ) {
        // Generic pattern to extract number after type name: "TypeName N (...)"
        // E.g. "Port N", "Element N", "Distance N"
        let pattern;

        if (itemType === "Ports") {
          pattern = /Port\s+(\d+)/i;
        } else if (itemType === "Lumped Elements") {
          pattern = /Element\s+(\d+)/i;
        } else if (itemType === "Distances") {
          pattern = /Distance\s+(\d+)/i;
        }

        if (pattern) {
          const aMatch = aName.match(pattern);
          const bMatch = bName.match(pattern);

          if (aMatch && bMatch) {
            const aNum = parseInt(aMatch[1], 10);
            const bNum = parseInt(bMatch[1], 10);
            return sortDir === "asc" ? aNum - bNum : bNum - aNum;
          }
        }
      }

      // Default to standard string comparison for other cases
      const aValue = aName.toLowerCase();
      const bValue = bName.toLowerCase();
      const comparison = aValue.localeCompare(bValue);
      return sortDir === "asc" ? comparison : -comparison;
    });
  }, [arrayModel, itemType, sortBy, sortDirection]);

  return (
    <div className="my-2 space-y-1 bg-gray-50 rounded-md p-2">
      <SidebarItemHeader
        itemType={itemType}
        objCounter={filteredAndSortedModels.length}
        onCollapseToggle={handleCollapseToggle}
        isCollapsed={isCollapsed}
        sortProps={
          itemType === "Objects"
            ? {
                sortBy,
                sortDirection,
                handleSort,
              }
            : undefined
        }
      />
      <SidebarItemList
        setChildContextMenuEnabled={setChildContextMenuEnabled}
        itemType={itemType}
        scene={scene}
        // Use sorted models
        models={filteredAndSortedModels}
        objCounter={filteredAndSortedModels.length}
        pasteSavedModel={pasteSavedModel}
        isCollapsed={isCollapsed}
      />
      <SidebarItemFooter
        itemType={itemType}
        objCounter={filteredAndSortedModels.length}
      />
    </div>
  );
};

export default SidebarItem;
