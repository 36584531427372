import React, { useState, useRef, useEffect } from "react";
import MyIcon from "../../../../assets/MyIcons";
import { Tooltip } from "react-tooltip";

// Define types for sorting
type SortOption = "name" | "material";
type SortDirection = "asc" | "desc";

interface SortProps {
  sortBy: SortOption;
  sortDirection: SortDirection;
  handleSort: (option: SortOption) => void;
}

interface SidebarItemHeaderProps {
  itemType: string;
  objCounter: number;
  onCollapseToggle: () => void;
  isCollapsed: boolean;
  sortProps?: SortProps; // Optional since only Objects have sorting
}

const SidebarItemHeader = ({
  itemType,
  objCounter,
  onCollapseToggle,
  isCollapsed,
  sortProps,
}: SidebarItemHeaderProps) => {
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const sortRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
        setShowSortDropdown(false);
      }
    };

    if (showSortDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSortDropdown]);

  // Handle sort button click
  const handleSortClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering collapse toggle
    setShowSortDropdown((prev) => !prev);
  };

  // Handle sort option selection
  const handleSortOption = (option: SortOption) => (e: React.MouseEvent) => {
    e.stopPropagation();
    sortProps?.handleSort(option);
    setShowSortDropdown(false);
  };

  return (
    <div
      className="bg-[#EAECF0] flex items-center px-2 py-0.5 text-sm font-normal text-[#101828] rounded-lg cursor-default"
      onClick={onCollapseToggle}
    >
      {/* Icon */}
      {itemType === "Objects" ? (
        <MyIcon name="3d-cube" />
      ) : itemType === "Ports" ? (
        <MyIcon name="ports" />
      ) : itemType === "Lumped Elements" ? (
        <MyIcon name="lumped-elements" />
      ) : itemType === "Distances" ? (
        <MyIcon name="distances" />
      ) : null}

      {/* Text area */}
      {itemType === "Objects" ? (
        <span className="ml-3 font-bold text-base">Objects</span>
      ) : itemType === "Ports" ? (
        <span className="ml-3 font-bold text-base">Ports</span>
      ) : itemType === "Lumped Elements" ? (
        <span className="ml-3 font-bold text-base">Lumped&nbsp;Elements</span>
      ) : itemType === "Distances" ? (
        <span className="ml-3 font-bold text-base">Distances</span>
      ) : null}

      <div className="w-full flex justify-end">
        {/* Sort Button - Only for Objects */}
        {itemType === "Objects" && sortProps && (
          <div className="w-full flex justify-end relative" ref={sortRef}>
            <button
              className="rounded-md flex items-center gap-1"
              onClick={handleSortClick}
            >
              <span
                data-tooltip-id="sort-tooltip"
                data-tooltip-content="Sort"
                data-tooltip-place="bottom"
              >
                <MyIcon name="sort" />
              </span>
              <Tooltip id="sort-tooltip" />
            </button>

            {showSortDropdown && (
              <div
                ref={dropdownRef}
                className={`origin-top-right ring-1 ring-black ring-opacity-5 absolute right-0 z-10 mt-2 w-max bg-white text-gray-700 drop-shadow-lg rounded-md overflow-hidden ${
                  showSortDropdown ? "dropdown-visible" : "dropdown-hidden"
                }`}
              >
                <button
                  onClick={handleSortOption("name")}
                  className="hover:bg-gray-200 active:bg-gray-300 block w-full px-4 py-2 text-left text-sm"
                >
                  <div className="flex items-center justify-between">
                    <span>Name</span>
                    {sortProps.sortBy === "name" && (
                      <span className="ml-2">
                        {sortProps.sortDirection === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>
                </button>
                <button
                  onClick={handleSortOption("material")}
                  className="hover:bg-gray-200 active:bg-gray-300 block w-full px-4 py-2 text-left text-sm"
                >
                  <div className="flex items-center justify-between">
                    <span>Material</span>
                    {sortProps.sortBy === "material" && (
                      <span className="ml-2">
                        {sortProps.sortDirection === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </div>
                </button>
              </div>
            )}
          </div>
        )}

        {/* Items Counter */}
        <span className="inline-flex justify-center items-center p-3 ml-3 w-2 h-2 text-xs font-medium bg-[#DEE2E8] text-[#344054] rounded-full">
          {objCounter}
        </span>
      </div>

      {/* Right Arrow */}
      <div
        className={`w-3 h-3 m-1 ml-2 transform transition-transform duration-500 cursor-pointer ${
          isCollapsed ? "" : "rotate-90"
        }`}
      >
        <MyIcon name="right-arrow" color="#667085" />
      </div>
    </div>
  );
};

export default SidebarItemHeader;
